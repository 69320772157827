import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const NavigationButtons = ({ ldisabled, rdisabled, handleBack, handleNext }) => {
    return (
        <Box sx={{ width: "100%", marginTop: "20px", marginBottom: "50px" }}>
            <IconButton
                style={{ float: 'left', fontWeight: 'bold', fontSize: '3rem' }}
                onClick={handleBack}
                disabled={ldisabled}
            >
                <ArrowBackIcon
                    style={{
                        fontSize: '3rem',
                        color: ldisabled ? 'gray' : 'blue'
                    }}
                />
            </IconButton>
            <IconButton
                style={{ float: 'right', fontWeight: 'bold', fontSize: '3rem' }}
                onClick={handleNext}
                disabled={rdisabled}
            >
                <ArrowForwardIcon
                    style={{
                        fontSize: '3rem',
                        color: rdisabled ? 'gray' : 'blue'
                    }}
                />
            </IconButton>
        </Box>
    );
};

export default NavigationButtons;