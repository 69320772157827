import React, { useState } from 'react';
import { FormControlLabel, Checkbox, Typography, Card } from '@mui/material';

function AgreementLab(props) {
    const [agreementChecked, setAgreementChecked] = useState(false);
    const agreementText = `
    Le macchine del presente laboratorio virtuale non sono persistenti e non sono sottoposte a procedure di backup.
    Questo significa che eventuali dati o documenti memorizzati all’interno della macchina virtuale andranno persi, allo spegnimento della stessa. Utilizzare i servizi Google G-Suite (es Google Drive) per salvare e ricaricare i propri elaborati nelle macchine virtuali tra una sessione e quella successiva.
    Le suddette macchine sono soggette all'attività di manutenzione nell'orario 00:00/07:00: il loro utilizzo in queste ore può presentare rallentamenti e/o riavvii delle stesse.
    [La presente nota è aggiornata al 07/06/2023]
  `;

  const { onCompletion } = props;

    var agreement;

    const toggleAgreement = () => {
        if (agreementChecked) {
            setAgreementChecked(false);
            onCompletion(false);
        } else {
            setAgreementChecked(true);
            onCompletion(true);
        }
    };


    agreement = (
        <Card sx={{ padding: 3 }}>


            <Typography variant="body1" align="center" style={{ width: "100%" }}>
                Nota sulla mancata persistenza delle macchine virtuali
            </Typography>

            <Typography variant="body2" align="justify" style={{ width: "100%", marginTop: 16 }}>
                {agreementText}
            </Typography>


            <FormControlLabel
                control={
                    <Checkbox
                        checked={agreementChecked}
                        onChange={toggleAgreement}
                        name="agreementCheckbox"
                        color="primary"
                    />
                }
                label="Accetto"
            />

        </Card>
    );

    return agreement;

}


export default AgreementLab;
