import React, { useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Can from "../components/Can";

import Home from './Home';
import Stanza from './stanzaWebex/Stanza';
import ReqLab from './reqLab/ReqLab';
import ReqExamLab from './reqExamLab/ReqExamLab';
import ReqExamLabServices from './reqExamLab/ReqExamLabServices';
import LabInventory from './labInventory/LabInventory';
import PubLab from './pubLab/PubLab';
import RegisterLab from './pubLab/RegisterLab';
import PubLabDettPage from './pubLab/PubLabDettPage';
import LabConsolle from './labConsolle/LabConsolle';
import LabConsolleSchedule from './labConsolle/LabConsolleSchedule';


function BootstrapNavbar(props) {
    const { setAuthInfo } = useAuth();

    var loginButton;
    var areaStudente;
    var areaGuide;
    var areaDocente;
    var areaTim;
    var itemVdiGuide;
    var itemJira;
    var itemStanza;
    var itemLabRequest;
    var itemLabPub;
    var itemLabExamRequest;
    var itemLabServicesExamRequest;
    var itemLabInventory;
    var areaAmministrazioneLab;
    var itemLabConsolle;
    var itemLabConsolleSchedule;

    useEffect(() => {
        setAuthInfo({
            apiAuthResponse: props.apiAuthResponse,
            apiAuthName: props.apiAuthName,
            apiAuthSurname: props.apiAuthSurname,
            apiAuthGroups: props.apiAuthGroups,
        });
    }, [setAuthInfo, props.apiAuthResponse, props.apiAuthName, props.apiAuthSurname, props.apiAuthGroups]); // Dipendenze



    if (props.apiAuthResponse) {
        loginButton = <Nav.Link href={process.env.REACT_APP_URL_AUTH_LOGOUT}>Logout</Nav.Link>;
    } else {
        loginButton = <Nav.Link href={process.env.REACT_APP_URL_AUTH_LOGIN}>Login</Nav.Link>;
    }

    if (props.apiAuthGroups) {

        areaGuide = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown title="Guide" id="basic-nav-dropdown">
                    {itemVdiGuide}
                    {itemJira}
                    {itemStanza}
                </NavDropdown>
            )}
            no={() => null}
        />

        areaStudente = <Can
            role={props.apiAuthGroups}
            perform="studentArea:visible"
            yes={() => (
                <NavDropdown title="Area studente" id="basic-nav-dropdown">
                </NavDropdown>
            )}
            no={() => null}
        />

        areaDocente = <Can
            role={props.apiAuthGroups}
            perform="teacherArea:visible"
            yes={() => (
                <NavDropdown title="Area docente" id="basic-nav-dropdown">
                    {itemLabRequest}
                    {itemLabPub}
                </NavDropdown>
            )}
            no={() => null}
        />

        areaAmministrazioneLab = <Can
            role={props.apiAuthGroups}
            perform="adminLabArea:visible"
            yes={() => (
                <NavDropdown title="Amministrazione" id="basic-nav-dropdown">
                    {itemLabConsolle}
                    {itemLabConsolleSchedule}
                    {itemLabInventory}
                    {itemLabExamRequest}
                    {itemLabServicesExamRequest}
                </NavDropdown>
            )}
            no={() => null}
        />

        itemLabConsolle = <Can
            role={props.apiAuthGroups}
            perform="labConsolle-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/labConsolle">Consolle Laboratori</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabConsolleSchedule = <Can
            role={props.apiAuthGroups}
            perform="labConsolle-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/labConsolleSchedule">Vista pianificazione Laboratori</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemVdiGuide = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown.Item
                    target="_blank"
                    href={process.env.REACT_APP_URL_VDI_DOC}>
                    Documentazione LIBaaS
                </NavDropdown.Item>
            )}
            no={() => null}
        />

        itemJira = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown.Item
                    target="_blank"
                    href={process.env.REACT_APP_URL_SERVICEDESK}>
                    Apri una richiesta di assistenza
                </NavDropdown.Item>
            )}
            no={() => null}
        />

        itemStanza = <Can
            role={props.apiAuthGroups}
            perform="labGuide-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/Stanza">Stanza di helpdesk (137 820 4977)</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabRequest = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/ReqLab">Richiesta nuovo laboratorio</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabPub = <Can
            role={props.apiAuthGroups}
            perform="labRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/PubLab">Gestione laboratori esistenti</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabExamRequest = <Can
            role={props.apiAuthGroups}
            perform="labExamRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/ReqExamLab">Richiesta nuovo laboratorio esame Horizon</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabServicesExamRequest = <Can
            role={props.apiAuthGroups}
            perform="labExamRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/ReqExamLabServices">Richiesta nuovo laboratorio esame Lab Services</NavDropdown.Item>
            )}
            no={() => null}
        />

        itemLabInventory = <Can
            role={props.apiAuthGroups}
            perform="labExamRequest-page:visit"
            yes={() => (
                <NavDropdown.Item as={Link} to="/LabInventory">Inventario LIB (laboratori fisici)</NavDropdown.Item>
            )}
            no={() => null}
        />

    }

    return (
        <React.Fragment>
            <Router>
                <Container>

                    <Navbar bg="light" expand="md" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Navbar.Brand as={Link} to="/">LIBaaS</Navbar.Brand>
                        <div>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav>
                                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                                    {areaStudente}
                                    {areaDocente}
                                    {areaTim}
                                    {areaAmministrazioneLab}
                                    {areaGuide}
                                </Nav>
                                {loginButton}
                            </Navbar.Collapse>
                        </div>
                    </Navbar>

                    <Routes>
                        <Route path="/" element={
                            <Home apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                                stuMatricola={props.stuMatricola}
                                stuIndiRes={props.stuIndiRes}
                                stuIndiDom={props.stuIndiDom}
                            />}
                        />
                        <Route path="/Stanza" element={
                            <Stanza
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/ReqLab" element={
                            <ReqLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/ReqExamLabServices" element={
                            <ReqExamLabServices
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/ReqExamLab" element={
                            <ReqExamLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/LabInventory" element={
                            <LabInventory
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/PubLab" element={
                            <PubLab
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/PubLab/register/:handle" element={
                            <RegisterLab
                                title="Pagina di registrazione ai Laboratori Virtuali"
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />
                        } />
                        <Route path="/labConsolle" element={
                            <LabConsolle
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/labConsolleSchedule" element={
                            <LabConsolleSchedule
                                apiAuthResponse={props.apiAuthResponse}
                                apiAuthName={props.apiAuthName}
                                apiAuthSurname={props.apiAuthSurname}
                                apiAuthGroups={props.apiAuthGroups}
                            />}
                        />
                        <Route path="/PubLabDettPage/" exact component={PubLabDettPage} />
                        <Route render={function () {
                            return <p>Pagina non trovata</p>
                        }} />
                    </Routes>

                </Container>
            </Router>
        </React.Fragment >


    );
}




export default BootstrapNavbar;