import React, { useState, useRef, useEffect, useMemo } from 'react';

import SelectAd from '../reqLab/SelectAd';
import ReqLabConfirm from '../reqLab/ReqLabConfirm';
import LabName from '../reqLab/LabName';
import LabDescription from '../reqLab/LabDescription';
import SelectAllocationMode from './SelectAllocationMode';
import LabServicesSelect from './LabServicesSelect';
import { Form, Container } from 'react-bootstrap';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import Summary from '../reqLab/Summary';
import '../../css/table-sort.css';


function ReqExamLabServicesPage(props) {
    const firstRender = useRef(true);

    const [adSel, setAdSel] = useState();
    const [coperEasy, setCoperEasy] = useState();
    const [coperTeacher, setCoperTeacher] = useState([]);
    const [labName, setLabName] = useState("");
    const [labDescription, setLabDescription] = useState("");
    const [labNotes, setLabNotes] = useState();
    const [labOtherActivities, setLabOtherActivities] = useState("");
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [statusRequest, setStatusRequest] = useState();
    const [isLabnameChecked, setIsLabnameChecked] = useState(true);
    const [isLabnameSpellChecked, setIsLabnameSpellChecked] = useState(true);
    const [isLabdescriptionSpellChecked, setIsLabdescriptionSpellChecked] = useState(true);
    const [isAdSpellChecked, setIsAdSpellChecked] = useState(true);
    const [students, setStudents] = useState([]);
    const [physicalLabs, setPhysicalLabs] = useState([]);
    const [labServicesVirtualmachines, setLabServicesVirtualmachines] = useState([]);
    const [labType, setLabType] = useState("exam");
    const [activeStep, setActiveStep] = useState(0);
    const [isSelectAdCompleted, setSelectAdCompleted] = useState(false);
    const [isLabNameCompleted, setLabNameCompleted] = useState(false);
    const [isLabDescCompleted, setLabDescCompleted] = useState(false);
    const [isLabServicesSelectCompleted, setIsLabServicesSelectCompleted] = useState(false);
    const [stepsData, setStepsData] = useState({});
    const [checkedImportEasy, setImportEasy] = useState(true);

    
    const updateStepData = (step, data) => {
        setStepsData(prevStepsData => ({
            ...prevStepsData,
            [step]: data,
        }));
    };

    const importEasyHandler = (event) => {
        setImportEasy(event.target.checked);
    }

    let emptyArray = [];
    let emptyObject = {};

    const steps = [
        'Select Ad',
        'Lab Name',
        'Lab Description',
        'Note aggiuntive',
        'VM IP Address',
        'Allocazione Postazione Esami',
        'Confirm'
    ];

    const handleNext = () => {
        // get the data of the current step
        let currentStepData;
        switch (activeStep) {
            case 0:
                currentStepData = adSel.anaAfCod;
                break;
            case 1:
                currentStepData = labName;
                break;
            case 2:
                currentStepData = labDescription;
                break;
            case 3:
                currentStepData = labNotes ? labNotes : "Nessuna nota aggiuntiva";
                break;
            case 4:
                currentStepData = `${labServicesVirtualmachines.length} vm istanziata/e`;
                break;
            case 5:
                let physicalLabsString = physicalLabs.join(', ');
                currentStepData = physicalLabsString
                break;
            default:
                break;
        }
        // update stepsData with the data of the current step
        updateStepData(`Step ${activeStep + 1}`, currentStepData);
        // increment activeStep
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleSelectAdCompletion = () => {
        // Code to handle the completion of the MultipleStepBaseImage component goes here
        setSelectAdCompleted(true);
    };

    const handleLabNameCompletion = () => {
        // Code to handle the completion of the MultipleStepBaseImage component goes here
        setLabNameCompleted(true);
    };

    const handleLabServicesSelectCompletion = () => {
        setIsLabServicesSelectCompleted(true);
    };

    const labColumns = useMemo(
        () => [
            {
                accessorKey: "element.properties.connectionProfile.privateIpAddress",
                header: "Private IP Address",
            },
            {
                accessorKey: "element.properties.connectionProfile.rdpAuthority",
                header: "Public IP Address and RDP Port",
            },
            {
                accessorKey: "element.properties.connectionProfile.adminUsername",
                header: "Admin username",
            },
            {
                accessorKey: "element.properties.connectionProfile.nonAdminUsername",
                header: "Non admin username",
            },
            {
                accessorKey: "element.properties.provisioningState",
                header: "Provisioning state",
            },
            {
                accessorKey: "element.properties.state",
                header: "State",
            },
        ], [],);

    const table = useMaterialReactTable({
        columns: labColumns,
        data: labServicesVirtualmachines || [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    });


    useEffect(() => {
        const formValidation = () => {
            var isOkLabName = false;
            var isOkLabDescription = false;
            var isOkAttivitaDidattica = false;

            if (!isLabnameChecked || labName === "" || !labName.match(/^[a-z|A-Z|0-9|-]*$/)) {
                isOkLabName = false
            } else {
                isOkLabName = true
                setIsLabnameSpellChecked(true);
            }

            if (labName.length > 0 && isOkLabName === false) {
                setIsLabnameSpellChecked(false);
            } else {
                setIsLabnameSpellChecked(true);
            }

            if (labDescription === "" || !labDescription.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkLabDescription = false
            } else {
                isOkLabDescription = true
                setIsLabdescriptionSpellChecked(true);
            }

            if (labDescription.length > 0 && isOkLabDescription === false) {
                setIsLabdescriptionSpellChecked(false);
            } else {
                setIsLabdescriptionSpellChecked(true);
            }

            if (labOtherActivities === "" || !labOtherActivities.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkAttivitaDidattica = false;
            } else {
                isOkAttivitaDidattica = true;
                setIsAdSpellChecked(true);
            }

            if (labOtherActivities.length > 0 && isOkAttivitaDidattica === false) {
                setIsAdSpellChecked(false);
            } else {
                setIsAdSpellChecked(true);
            }

            if (isOkAttivitaDidattica === false && adSel !== undefined) {
                isOkAttivitaDidattica = true;
            }

            return (isOkLabName && isOkLabDescription && isOkAttivitaDidattica);
        }

        // skippo la prima renderizzazione
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setIsSubmitble(formValidation());

    }, [labName, labDescription, isLabnameChecked, labOtherActivities, adSel]);


    const usageType = () => {
        return (
            <React.Fragment>
                <Form onSubmit={onSubmit}>
                    <Container>
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <Form.Group controlId="formLabRequest" style={{ width: "85%" }}>
                                <SelectAd
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setAdSelected={setAdSel}
                                    setCoperEasy={setCoperEasy}
                                    isAdSpellChecked={isAdSpellChecked}
                                    setCoperTeacher={setCoperTeacher}
                                    labOtherActivitiesSelected={setLabOtherActivities}
                                    labName={labName}
                                    onCompletion={handleSelectAdCompletion}
                                />
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext} disabled={!isSelectAdCompleted}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 1 && (
                            <Form.Group style={{ width: "100%" }}>
                                <Summary stepsData={stepsData} />
                                <LabName
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    adSel={adSel}
                                    labNameSelected={setLabName}
                                    labName={labName}
                                    setIsLabnameChecked={setIsLabnameChecked}
                                    isLabnameSpellChecked={isLabnameSpellChecked}
                                    isSubmitted={isSubmitted}
                                    onCompletion={handleLabNameCompletion}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext} disabled={!isLabNameCompleted}>Next</Button>
                            </Form.Group>
                        )}
                        {activeStep === 2 && (
                            <Form.Group controlId="formLabRequestSw" style={{ width: "85%", marginTop: "50px" }}>
                                <Summary stepsData={stepsData} />
                                <LabDescription
                                    labDescriptionHandler={labDescriptionHandler}
                                    isLabdescriptionSpellChecked={isLabdescriptionSpellChecked}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext} disabled={!isLabDescCompleted}>Next</Button>
                            </Form.Group>
                        )}

                        {activeStep === 3 && (

                            <Form.Group style={{ width: "100%", marginTop: "50px" }}>
                                <Summary stepsData={stepsData} />
                                <Form.Label>Note aggiuntive</Form.Label>
                                <Form.Control style={{ width: "85%" }}
                                    as="textarea"
                                    rows="3"
                                    maxLength="2000"
                                    placeholder="Inserire eventuali note aggiuntive"
                                    onChange={labNotesHandler}
                                />
                                <Form.Check
                                    type="checkbox"
                                    label="Importare il calendario da EasyEvents GDA"
                                    id="formCheckbox"
                                    checked={checkedImportEasy}
                                    onChange={importEasyHandler}
                                />
                                <Form.Control
                                    type="text"
                                    readOnly
                                    defaultValue="Abilitando la checkbox verranno importati tutti gli eventi associati al docente dal sistema GDA."
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>
                            </Form.Group>

                        )}

                        {activeStep === 4 && (
                            <Form.Group style={{ width: "100%", marginTop: "50px" }}>
                                <Summary stepsData={stepsData} />
                                <Form.Label>VM Lab Services IP Address</Form.Label>
                                <MaterialReactTable table={table} />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>

                            </Form.Group>

                        )}

                        {activeStep === 5 && (
                            <Form.Group style={{ width: "100%" }}>
                                <Summary stepsData={stepsData} />
                                <SelectAllocationMode
                                    apiAuthResponse={props.apiAuthResponse}
                                    setStudents={setStudents}
                                    students={students}
                                    setPhysicalLabs={setPhysicalLabs}
                                    physicalLabs={physicalLabs}
                                    labServicesVirtualmachines={labServicesVirtualmachines}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                                <Button style={{ float: 'right', fontWeight: 'bold' }} onClick={handleNext}>Next</Button>

                            </Form.Group>

                        )}
                        {activeStep === 6 && (
                            <Form.Group style={{ width: "100%", marginTop: "50px" }}>
                                <Summary stepsData={stepsData} />
                                <ReqLabConfirm
                                    isSubmitted={isSubmitted}
                                    isSubmitble={isSubmitble}
                                    statusRequest={statusRequest}
                                    apiAuthGroups={props.apiAuthGroups}
                                    isPkgListModified={false}
                                    buttonDescription={"Istanzia laboratorio"}
                                />
                                <Button style={{ float: 'left', fontWeight: 'bold' }} onClick={handleBack}>Back</Button>
                            </Form.Group>
                        )}
                    </Container>
                </Form >
            </React.Fragment >

        );

    }

    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var myTeachers = [];
        var labEvents = [];

        var url;


        url = process.env.REACT_APP_URL_SAVE_REQ_LAB;

        if (coperTeacher.length > 0) {
            myTeachers = coperTeacher;
        } else {
            myTeachers = [{ email: props.apiAuthResponse, partitions: [0] }];
        }

        if (coperEasy.length > 0 && checkedImportEasy) {
            labEvents = coperEasy.map(event => ({
                ...event,
                scheduleType: "easy",
                teacher: { email: props.apiAuthResponse, partition: 0 }
            }));
        } else {
            labEvents = [];
        }

        const payload = (
            {
                idLab: emptyObject.idLab,
                email: props.apiAuthResponse,
                //teachers: [{ email: props.apiAuthResponse, partitions: [0] }],
                //teachers: coperTeacher,
                teachers: myTeachers,
                labName: labName,
                labDescription: labDescription,
                labType: labType,
                repoPkgBundleSel: emptyArray,
                repoPkgSel: emptyArray,
                adSel: adSel,
                labOtherActivities: labOtherActivities,
                labNotes: labNotes,
                baseImage: {
                    os: emptyObject.imageOs,
                    name: emptyObject.imageName,
                    des: emptyObject.imageDes,
                    size: emptyObject.imageSize,
                    feed: emptyObject.feed,
                    feedBundle: emptyObject.feedBundle,
                    imageType: emptyObject.imageType,
                    destination: emptyObject.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //quota: quota,
                    schedulerLab: labEvents,
                },
                students: students,
                physicalLabs: physicalLabs,
            }
        );

        formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

        setTimeout(async () => {
            console.log("payload (ReqExamLabPage): " + JSON.stringify(payload));
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }



    const labDescriptionHandler = (event) => {
        setLabDescription(event.target.value);
        setLabDescCompleted(true);
    }

    const labNotesHandler = (event) => {
        setLabNotes(event.target.value);
    }

    return (
        <React.Fragment>
            <h2 className="title-page">Richiesta Laboratorio Virtuale per esame</h2>
            <p>Da questa pagina è possibile attivare un Laboratorio Virtuale in modalità Esame</p>

            <Container>
                <Form>
                    <Form.Group style={{ width: "100%" }}>
                        <Form.Label>Nome richiedente</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${props.apiAuthName}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>Cognome richiedente</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${props.apiAuthSurname}`}
                            readOnly
                        />
                        <br />
                        <Form.Label>E-mail richiedente</Form.Label>
                        <Form.Control style={{ width: "100%" }}
                            type="text"
                            placeholder={`${props.apiAuthResponse}`}
                            readOnly
                        />
                        <br />
                    </Form.Group>
                </Form>
            </Container>

            <LabServicesSelect
                setLabServicesVirtualmachines={setLabServicesVirtualmachines}
                setLabType={setLabType}
                onComplete={handleLabServicesSelectCompletion}
            />


            <br />


            {isLabServicesSelectCompleted && usageType()}


        </React.Fragment>
    );

}

export default ReqExamLabServicesPage;
