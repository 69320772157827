import React from 'react';
import { Alert, AlertTitle, Card, Button, Link } from '@mui/material';
import Can from "../Can";

function ReqLabConfirm(props) {
    var ret;
    if (props.isSubmitble) {
        if (!props.isSubmitted) {
            ret = (
                <Can
                    role={props.apiAuthGroups}
                    perform="labRequest-page:confirm"
                    yes={() => (
                        <Card sx={{ padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="contained" type="submit">
                                {props.buttonDescription}
                            </Button>
                        </Card>
                    )}
                    no={() => (
                        <Card sx={{ padding: 3 }}>
                            <Alert severity="warning">
                                <AlertTitle>Funzionalità di richiesta Laboratorio Virtuale non abilitata</AlertTitle>
                            </Alert>
                        </Card>
                    )}
                />



            );
        } else {
            if (props.statusRequest === "success") {
                ret = (
                    <React.Fragment>
                        <Alert variant="success">
                            <AlertTitle>Richiesta acquisita con successo!</AlertTitle>
                            <p>
                                Il Laboratorio Virtuale richiesto è in lavorazione. È possibile consultare lo stato di avanzamento
                                della richiesta da <Link href="/PubLab">Gestione Laboratori</Link>.
                            </p>

                        </Alert>
                    </React.Fragment>
                );
            } else {
                ret = (
                    <React.Fragment>
                        <Alert variant="danger">

                            <AlertTitle>Si è verificato un errore!</AlertTitle>
                            <p>
                                Contattare l'assistenza.
                            </p>
                        </Alert>
                    </React.Fragment>
                );

            };
        }
    } else {
        ret = (
            <Button variant="contained" type="submit" disabled={true}>
                {props.buttonDescription}
            </Button>
        );
    }
    return ret;
}

export default ReqLabConfirm;