import React, { useState } from 'react';
import { Grid, Box, Card, Divider, Button, Typography, TextField } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import SchedulerLab from '../scheduleLab/SchedulerLab'
import ReqLabConfirm from '../reqLab/ReqLabConfirm';

import LabConsolleImportFromEasy from './LabConsolleImportFromEasy';



function LabConsolleScheduleDettPage(props) {
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [labEvents, setLabEvents] = useState([]);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [statusRequest, setStatusRequest] = useState();

    const {
        labProps,
    } = props;

    var ret = null;


    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var url;

        url = process.env.REACT_APP_URL_BOOK_LAB;

        const payload = (
            {
                idLab: props.labProps.idLab,
                email: props.labProps.email,
                baseImage: {
                    os: props.labProps.imageOs,
                    name: props.labProps.imageName,
                    des: props.labProps.imageDes,
                    size: props.labProps.imageSize,
                    feed: props.labProps.imageFeed,
                    feedBundle: props.labProps.imageFeedBundle,
                    imageType: props.labProps.imageType,
                    destination: props.labProps.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //                    quota: quota,
                    schedulerLab: labEvents,
                }
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log("payload (ReqLabPage): " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    ret = (
        <React.Fragment>
            <Typography variant="h4" className="title-page">
                Pianificazione Laboratorio Virtuale
            </Typography>
            <Box sx={{ padding: 3 }}>
                <Typography variant="body1" gutterBottom>
                    Da questa pagina è possibile pianificare un Laboratorio Virtuale
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>idLab</strong> {labProps.idLab}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Nome laboratorio</strong> {labProps.labName}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Descrizione</strong> {labProps.labDescription}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Immagine base</strong> {labProps.baseImage.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Dimensionamento</strong> {labProps.baseImage.size}
                </Typography>

            </Box>



            <Divider />

            <form onSubmit={onSubmit}>

                <Grid container spacing={2} style={{ width: "100%" }}>
                    <Grid item xs={12}>
                        <Typography>Attività didattica collegata al laboratorio virtuale</Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            Cliccare su "Importa pianificazioni da Easy" per importare le pianificazioni aggiornate da EasyRoom.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Card style={{ padding: '1rem' }}>
                            <Typography variant="subtitle1">Attività didattica</Typography>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={`${labProps.adSel.anaAfDescIta}`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                margin="normal"
                            />
                            <Typography variant="subtitle1">Modulo</Typography>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={`${labProps.adSel.anaModDescIta}`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                margin="normal"
                            />
                            <Typography variant="subtitle1">Codice Easy</Typography>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={`${labProps.adSel.easyAdCod}`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                margin="normal"
                            />
                            <LabConsolleImportFromEasy
                                buttonDescription={"Importa pianificazione da Easy"}
                                labProps={labProps}
                                setLabEvents={setLabEvents}
                                setIsSubmitted={setIsSubmitted}
                                setIsSubmitble={setIsSubmitble}
                                setStatusRequest={setStatusRequest}
                            />
                        </Card>
                    </Grid>
                </Grid>

                <br />

                <SchedulerLab
                    email={props.apiAuthResponse}
                    baseImage={labProps}
                    labEvents={labEvents}
                    setLabEvents={setLabEvents}
                    setIsSubmitble={setIsSubmitble}
                    isSubmitted={isSubmitted}
                    defaultView={'week'}
                    defaultDate={new Date()}
                />
                <br />
                <div>
                    <ReqLabConfirm
                        isSubmitted={isSubmitted}
                        isSubmitble={isSubmitble}
                        statusRequest={statusRequest}
                        apiAuthGroups={props.apiAuthGroups}
                        buttonDescription={"Salva pianificazione"}
                    />
                    <br />
                    <ClipLoader
                        size={35}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </div>

            </form>

            <br />

            <Divider />
            <Button
                size="sm"
                variant="contained"
                onClick={() => { props.setIsShowScheduleLab(false) }}
            >
                Torna alla vista complessiva dei laboratori
            </Button>


        </React.Fragment>
    );





    if (labProps) {
        return ret;
    } else {
        return (
            <Button
                size="sm"
                variant="contained"
                onClick={() => { props.setIsShowScheduleLab(false) }}
            >
                Torna alla vista complessiva dei laboratori
            </Button>
        );
    }

}

export default LabConsolleScheduleDettPage;
