import React, { useState } from "react";
import PubLabDettPage from "./PubLabDettPage";
import PubLabInfiniteTable from "./PubLabInfiniteTable";
import LabConsolleDroppedPage from "../labConsolle/LabConsolleDroppedPage";
import LabConsolleClonePage from "../labConsolle/LabConsolleClonePage";
import LabConsolleScheduleDettPage from "../labConsolle/LabConsolleScheduleDettPage";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';


const queryClient = new QueryClient();


function PubLabPage(props) {
    const [selectedLabProps, setSelectedLabProps] = useState();
    const [isShowSelectedLab, setIsShowSelectedLab] = useState(false);
    const [isShowScheduleLab, setIsShowScheduleLab] = useState(false);
    const [isShowDropLab, setIsShowDropLab] = useState(false);
    const [isShowCloneLab, setIsShowCloneLab] = useState(false);


    const pubLabPage = () => {

        var retPub = null;

        if (!isShowSelectedLab && !isShowScheduleLab && !isShowDropLab && !isShowCloneLab) {
            retPub = (
                <QueryClientProvider client={queryClient}>
                    <PubLabInfiniteTable
                        apiAuthResponse={props.apiAuthResponse}
                        setSelectedLabProps={setSelectedLabProps}
                        setIsShowSelectedLab={setIsShowSelectedLab}
                        setIsShowScheduleLab={setIsShowScheduleLab}
                        setIsShowDropLab={setIsShowDropLab}
                        setIsShowCloneLab={setIsShowCloneLab}
                        isAdmin={props.isAdmin}


                    />
                </QueryClientProvider>
            );
        }

        return retPub;
    };

    const pubLabDettPage = () => {
        var retDett = null;

        if (isShowSelectedLab) {
            retDett = (
                <PubLabDettPage
                    labProps={selectedLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowSelectedLab={setIsShowSelectedLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }


    const pubLabSchedulePage = () => {
        var retDett = null;

        if (isShowScheduleLab) {
            retDett = (
                <LabConsolleScheduleDettPage
                    labProps={selectedLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowScheduleLab={setIsShowScheduleLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }

    const pubLabDropPage = () => {
        var retDett = null;

        if (isShowDropLab) {
            retDett = (
                <LabConsolleDroppedPage
                    labProps={selectedLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowDropLab={setIsShowDropLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }


    const pubLabClonePage = () => {
        var retDett = null;

        if (isShowCloneLab) {
            retDett = (
                <LabConsolleClonePage
                    labProps={selectedLabProps}
                    apiAuthResponse={props.apiAuthResponse}
                    apiAuthName={props.apiAuthName}
                    apiAuthSurname={props.apiAuthSurname}
                    apiAuthGroups={props.apiAuthGroups}
                    setIsShowCloneLab={setIsShowCloneLab}
                    isAdmin={props.isAdmin}
                />
            );
        }

        return retDett;
    }

    var ret = (
        <React.Fragment>
            {pubLabDettPage()}
            {pubLabSchedulePage()}
            {pubLabDropPage()}
            {pubLabClonePage()}
            {pubLabPage()}
        </React.Fragment>
    );

    return ret;


}

export default PubLabPage;
