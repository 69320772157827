import React, { useEffect, useState, useRef } from 'react';
import { Typography, Container, Stepper, Step, StepLabel, Box, Divider, TextField } from '@mui/material';
import { Card, Button } from '@mui/material';
import AgreementLab from '../reqLab/AgreementLab';
import SelectAd from '../reqLab/SelectAd';
import SelectRepoPkg from '../reqLab/SelectRepoPkg';
import LabName from '../reqLab/LabName';
import LabDescription from '../reqLab/LabDescription';
import ReqLabConfirm from '../reqLab/ReqLabConfirm';
import SelectAllocationMode from '../reqExamLab/SelectAllocationMode';
import Summary from '../reqLab/Summary';
import SelectExpDate from '../reqLab/SelectExpDate';
import NavigationButtons from '../reqLab/NavigationButtons';
import StudentList from '../pubLab/StudentList';



function LabConsolleClonePage(props) {
    const initialExpirationDate = new Date(new Date().setMonth(new Date().getMonth() + 18));

    const firstRender = useRef(true);
    const [repoPkgBundleSel, setRepoPkgBundleSel] = useState([]);
    const [repoPkgSel, setRepoPkgSel] = useState([]);
    const [installedPackages] = useState([]);
    const [isPkgListModified, setIsPkgListModified] = useState(false);
    const [statusRequest, setStatusRequest] = useState();
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isLoading, setIsLoading] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [physicalLabs, setPhysicalLabs] = useState([]);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [stepsData, setStepsData] = useState({});
    const [expirationMonthDuration, setExpirationMonthDuration] = useState('18');
    const [expirationDate, setExpirationDate] = useState(initialExpirationDate);
    const [isSelectAdCompleted, setSelectAdCompleted] = useState(false);
    const [isAgreementCompleted, setAgreementCompleted] = useState(false);
    const [adSel, setAdSel] = useState([]);
    const [coperEasy, setCoperEasy] = useState([]);
    const [coperTeacher, setCoperTeacher] = useState([]);
    const [isAdSpellChecked, setIsAdSpellChecked] = useState(true);
    const [labOtherActivities, setLabOtherActivities] = useState("");
    const [checkedImportEasy, setImportEasy] = useState([]);
    const [students, setStudents] = useState([]);
    const [isLabNameCompleted, setLabNameCompleted] = useState(false);
    const [labName, setLabName] = useState("");
    const [isLabnameChecked, setIsLabnameChecked] = useState(true);
    const [isLabnameSpellChecked, setIsLabnameSpellChecked] = useState(true);
    const [isLabdescriptionSpellChecked, setIsLabdescriptionSpellChecked] = useState(true);
    const [isLabDescCompleted, setLabDescCompleted] = useState(false);
    const [labDescription, setLabDescription] = useState("");
    const [labNotes, setLabNotes] = useState();


    useEffect(() => {
        const formValidation = () => {
            var isOkLabName = false;
            var isOkLabDescription = false;
            var isOkAttivitaDidattica = false;

            if (!isLabnameChecked || labName === "" || !labName.match(/^[a-z|A-Z|0-9|-]*$/)) {
                isOkLabName = false
            } else {
                isOkLabName = true
                setIsLabnameSpellChecked(true);
            }

            if (labName.length > 0 && isOkLabName === false) {
                setIsLabnameSpellChecked(false);
            } else {
                setIsLabnameSpellChecked(true);
            }

            if (labDescription === "" || !labDescription.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkLabDescription = false
            } else {
                isOkLabDescription = true
                setIsLabdescriptionSpellChecked(true);

            }

            if (labDescription.length > 0 && isOkLabDescription === false) {
                setIsLabdescriptionSpellChecked(false);
            } else {
                setIsLabdescriptionSpellChecked(true);
            }

            if (labOtherActivities === "" || !labOtherActivities.match(/^[a-z|A-Z|0-9|-\s]*$/)) {
                isOkAttivitaDidattica = false;
            } else {
                isOkAttivitaDidattica = true;
                setIsAdSpellChecked(true);
            }

            if (labOtherActivities.length > 0 && isOkAttivitaDidattica === false) {
                setIsAdSpellChecked(false);
            } else {
                setIsAdSpellChecked(true);
            }

            if (isOkAttivitaDidattica === false && adSel !== undefined) {
                isOkAttivitaDidattica = true;
            }

            return (isOkLabName && isOkLabDescription && isOkAttivitaDidattica);
        }

        // skippo la prima renderizzazione
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        setIsSubmitble(formValidation());

    }, [labName, labDescription, isLabnameChecked, adSel, labOtherActivities]);

    const stepsExam = [
        'Pacchetti aggiuntivi',
        'Durata laboratorio',
        'Attività didattica',
        'Nome Laboratorio',
        'Descrizonione Laboratorio',
        'Note aggiuntive',
        'Allocazione postazioni esame',
        'Agreement',
        'Conferma'
    ];

    const stepsStandard = [
        'Pacchetti aggiuntivi',
        'Durata laboratorio',
        'Attività didattica',
        'Nome Laboratorio',
        'Descrizonione Laboratorio',
        'Note aggiuntive',
        'Studenti iscritti',
        'Agreement',
        'Conferma'
    ];

    const {
        labProps,
        isAdmin,
    } = props;

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('it-IT', options);
    };


    const handleSelectAdCompletion = () => {
        setSelectAdCompleted(true);
    };

    const handleAgreementCompletion = (isAccepted) => {
        if (isAccepted) {
            setAgreementCompleted(true);
        } else {
            setAgreementCompleted(false);
        }
    };
    const handleLabNameCompletion = (nameOk) => {
        if (nameOk) {
            setLabNameCompleted(true);
        } else {
            setLabNameCompleted(false);
        }
    };

    const labDescriptionHandler = (event) => {
        var labDesc = event.target.value;
        if (labDesc.length > 0) {
            setLabDescription(labDesc);
            setLabDescCompleted(true);
        } else {
            setLabDescCompleted(false);
        }
    }

    const labNotesHandler = (event) => {
        setLabNotes(event.target.value);
    }

    const handleExpDateChange = (newExpDate) => {
        const currentDate = new Date();
        const monthDurations = {
            '3': 3,
            '6': 6,
            '12': 12,
            '18': 18
        };

        // Calcola la nuova data di scadenza
        const monthsToAdd = monthDurations[newExpDate] || 0;
        const calculatedExpirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd));
        const isoExpirationDate = calculatedExpirationDate.toISOString();

        // Aggiorna gli stati
        setExpirationMonthDuration(newExpDate);
        setExpirationDate(isoExpirationDate);

        console.log("DEBUG expirationMonthDuration: " + newExpDate);
        console.log("DEBUG expirationDate: " + isoExpirationDate);
    };


    const handleNext = () => {
        // get the data of the current step
        let currentStepData;
        switch (activeStep) {
            // selezione pacchetti
            case 0:
                let repoPkgSelString;
                if (repoPkgSel.length === 0) {
                    repoPkgSelString = "Nessun pacchetto addizionale";
                } else {
                    repoPkgSelString = repoPkgSel.map(item => item.value).join(', ');
                }

                currentStepData = (
                    <Typography>
                        Pacchetti aggiuntivi selezionati: <Typography component="span" fontWeight="bold">{repoPkgSelString}</Typography>
                    </Typography>
                );
                break;
            // selezione data di scadenza
            case 1:
                currentStepData = (
                    <Typography>
                        Data di scadenza: <Typography component="span" fontWeight="bold">{expirationMonthDuration} mesi (data di scadenza: {formatDate(expirationDate)})</Typography>
                    </Typography>
                );
                break;
            // attività didattica
            case 2:
                currentStepData = (
                    <Typography>
                        Attività didattica: <Typography component="span" fontWeight="bold">[{adSel.anaAfCod}-{adSel.anaModCod}] {adSel.anaAfDescIta}</Typography>
                    </Typography>
                );
                break;
            // nome laboratorio
            case 3:
                currentStepData = (
                    <Typography>
                        Nome laboratorio: <Typography component="span" fontWeight="bold">{labName}</Typography>
                    </Typography>
                );
                break;
            // descrizione laboratorio
            case 4:
                currentStepData = (
                    <Typography>
                        Descrizione laboratorio: <Typography component="span" fontWeight="bold">{labDescription}</Typography>
                    </Typography>
                );
                break;
            // note aggiuntive
            case 5:
                currentStepData = (
                    <Typography>
                        Note aggiuntive: <Typography component="span" fontWeight="bold">{labNotes}</Typography>
                    </Typography>
                );
                break;
            // selezione lista studenti da escludere (standard) o allocazione postazioni esame (exam)
            case 6:
                currentStepData = (
                    labProps.labType === "exam" ? (
                        <Typography>
                            Allocazione postazioni esame: <Typography component="span" fontWeight="bold">{physicalLabs.join(', ')}</Typography>
                        </Typography>
                    ) : (
                        <Typography>
                            Elenco studenti iscritti aggiornato: <Typography component="span" fontWeight="bold">{students.map(student => student.email).join(', ')}</Typography>
                        </Typography>
                    )
                );
                break;
            // agreement
            case 7:
                currentStepData = (
                    <Typography>
                        Nota sulla mancata persistenza accettata
                    </Typography>
                );
                break;
            default:
                break;
        }


        // update stepsData with the data of the current step
        setStepsData(prevStepsData => ({
            ...prevStepsData,
            [`Step ${activeStep + 1}`]: currentStepData,
        }));

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        switch (activeStep) {
            // 
            case 0:
                break;
            case 1:
                break;
            case 2:
                break;
            case 3:
                break;
            case 4:
                break;
            case 5:
                break;
            case 6:
                setSelectAdCompleted(false);
                break;
            case 7:
                setSelectAdCompleted(false);
                setAgreementCompleted(false);
                break;
            case 8:
                setAgreementCompleted(false);
                break;
            default:
                break;
        }

        // Cancella i dati dello step corrente
        setStepsData((prevStepsData) => {
            const newStepsData = { ...prevStepsData };
            delete newStepsData[`Step ${activeStep}`];
            return newStepsData;
        });

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const usageType = () => {
        const stepsToUse = labProps.labType === "exam" ? stepsExam : stepsStandard;

        return (
            <React.Fragment>
                <form onSubmit={onSubmit}>

                    <Container>
                        <Summary stepsData={stepsData} />
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {stepsToUse.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={true} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectRepoPkg
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setRepoPkgSel={setRepoPkgSel}
                                    setRepoPkgBundleSel={setRepoPkgBundleSel}
                                    installedPackages={labProps.repoPkgSel}
                                    //installedPackages={installedPackages}
                                    baseImage={labProps.baseImage}
                                    setIsPkgListModified={setIsPkgListModified}
                                />
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectExpDate
                                    expirationDate={labProps.expirationDate}
                                    expirationMonthDuration={expirationMonthDuration}
                                    onExpDateChange={handleExpDateChange} />
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isSelectAdCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectAd
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setAdSelected={setAdSel}
                                    adSelected={labProps.adSel}
                                    setCoperEasy={setCoperEasy}
                                    isAdSpellChecked={isAdSpellChecked}
                                    setCoperTeacher={setCoperTeacher}
                                    labOtherActivitiesSelected={setLabOtherActivities}
                                    labName={labProps.labName}
                                    onCompletion={handleSelectAdCompletion}
                                    setImportEasy={setImportEasy}
                                    checkedImportEasy={checkedImportEasy}
                                />
                                <NavigationButtons ldisabled={false} rdisabled={!isSelectAdCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 3 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isLabNameCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <LabName
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    adSel={adSel}
                                    labNameSelected={setLabName}
                                    setIsLabnameChecked={setIsLabnameChecked}
                                    isLabnameSpellChecked={isLabnameSpellChecked}
                                    isSubmitted={isSubmitted}
                                    onCompletion={handleLabNameCompletion}
                                />
                            </Box>
                        )}
                        {activeStep === 4 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isLabDescCompleted || !isLabdescriptionSpellChecked} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <LabDescription
                                    labDescriptionHandler={labDescriptionHandler}
                                    isLabdescriptionSpellChecked={isLabdescriptionSpellChecked}
                                />
                            </Box>
                        )}
                        {activeStep === 5 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <label style={{ width: "85%", marginTop: "50px" }}> Note aggiuntive </label>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <Card sx={{ padding: 3 }}>
                                    <TextField
                                        multiline
                                        rows={3}
                                        maxLength={2000}
                                        placeholder="Inserire eventuali note aggiuntive"
                                        onChange={labNotesHandler}
                                        fullWidth
                                    />
                                </Card>
                            </Box>
                        )}
                        {activeStep === 6 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack} handleNext={handleNext} />
                                {labProps.labType === "exam" ? (
                                    <SelectAllocationMode
                                        apiAuthResponse={props.apiAuthResponse}
                                        setStudents={setStudents}
                                        students={students}
                                        setPhysicalLabs={setPhysicalLabs}
                                        physicalLabs={labProps.physicalLabs}
                                    />
                                ) : (
                                    <StudentList
                                        status={labProps.status}
                                        isAdmin={isAdmin}
                                        apiAuthGroups={props.apiAuthGroups}
                                        isLoading={isLoading}
                                        students={labProps.students}
                                        teachers={labProps.teachers}
                                        setStudents={setStudents}
                                    />
                                )}
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack} handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 7 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isAgreementCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <AgreementLab
                                    onCompletion={handleAgreementCompletion}
                                />
                            </Box>
                        )}
                        {activeStep === 8 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={isSubmitted} rdisabled={true} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <ReqLabConfirm
                                    isSubmitted={isSubmitted}
                                    isSubmitble={isSubmitble}
                                    statusRequest={statusRequest}
                                    apiAuthGroups={props.apiAuthGroups}
                                    isPkgListModified={isPkgListModified}
                                    buttonDescription={"Clona laboratorio"}
                                />
                            </Box>
                        )}
                    </Container>
                </form>
            </React.Fragment>
        );


    }


    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var tempArraySelectedPackages = [];
        var myTeachers = [];
        var labEvents = [];
        var url;

        url = process.env.REACT_APP_URL_SAVE_REQ_LAB;

        tempArraySelectedPackages = installedPackages.concat(repoPkgSel);

        if (coperTeacher.length > 0) {
            myTeachers = coperTeacher;
        } else {
            myTeachers = [{ email: props.apiAuthResponse, partitions: [0] }];
        }

        if (coperEasy.length > 0 && checkedImportEasy) {
            labEvents = coperEasy.map(event => ({
                ...event,
                scheduleType: "easy",
                teacher: { email: props.apiAuthResponse, partition: 0 }
            }));
        } else {
            labEvents = [];
        }

        const payload = (
            {
                idLab: labProps.idLab,
                email: props.apiAuthResponse,
                teachers: myTeachers,
                labName: labName,
                labType: labProps.labType,
                expirationDate: expirationDate,
                labDescription: labDescription,
                repoPkgBundleSel: repoPkgBundleSel,
                repoPkgSel: tempArraySelectedPackages,
                adSel: adSel,
                labOtherActivities: labProps.labOtherActivities,
                labNotes: labNotes,
                baseImage: {
                    os: labProps.baseImage.os,
                    name: labProps.baseImage.name,
                    des: labProps.baseImage.des,
                    size: labProps.baseImage.size,
                    feed: labProps.baseImage.feed,
                    feedBundle: labProps.baseImage.feedBundle,
                    imageType: labProps.baseImage.imageType,
                    destination: labProps.baseImage.destination,
                },
                usageData: {
                    maxStudentsPerPartition: 0,
                    //quota: quota,
                    schedulerLab: labEvents,
                },
                students: [],
                physicalLabs: [],
            }
        );

        formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

        setTimeout(async () => {
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    let ret = (

        <React.Fragment>
            <Typography variant="h4" className="title-page">
                Clonazione laboratorio virtuale
            </Typography>
            <Box sx={{ padding: 3 }}>
                <Typography variant="body1" gutterBottom>
                    Da questa pagina è possibile clonare un Laboratorio Virtuale
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>idLab</strong> {labProps.idLab}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Nome laboratorio</strong> {labProps.labName}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Descrizione</strong> {labProps.labDescription}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Immagine base</strong> {labProps.baseImage.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Dimensionamento</strong> {labProps.baseImage.size}
                </Typography>
            </Box>

            {usageType()}

            <Divider sx={{ marginY: 2 }} />
            <Card sx={{ padding: 3, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    type="submit"
                    size="sm"
                    onClick={() => { props.setIsShowCloneLab(false) }}
                >
                    Torna alla vista complessiva dei laboratori
                </Button>
            </Card>
        </React.Fragment>
    );


    if (labProps) {
        return ret;
    } else {
        return (
            <Button
                size="sm"
                variant="contained"
                type="submit"
                onClick={() => { props.setIsShowCloneLab(false) }}
            >
                Torna alla vista complessiva dei laboratori
            </Button>
        );
    }

}

export default LabConsolleClonePage;
