import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

const StudentList = (props) => {

    const {
        isAdmin,
        apiAuthResponse,
        students,
        setStudents,
        teachers
    } = props;

    const [rowSelection, setRowSelection] = useState({});
    const [tabTeachers, setTabTeachers] = useState([]);
    const [tabStudents, setTabStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);


    const labelStudentBooked = isAdmin ? "Studenti iscritti" : "Propri studenti iscritti";


    const teacherColumns = useMemo(
        () => [
            {
                accessorKey: 'teacher',
                header: 'Docente',
                size: 120,
            }
        ],
        [],
    );

    const studentsColumns = useMemo(
        () => [
            {
                accessorKey: 'teacher',
                header: 'Docente',
                size: 120,
            },
            {
                accessorKey: 'partition',
                header: 'Turno',
                size: 90,
            },
            {
                accessorKey: 'email',
                header: 'Studente',
                size: 150,
            },
            {
                accessorKey: "registrationDate",
                header: "Data iscrizione",
                size: 120,
            },
        ],
        [],
    );


    const teachersTable = useMaterialReactTable({
        columns: teacherColumns,
        data: tabTeachers || [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    });

    const studentsTable =
        useMaterialReactTable({
            columns: studentsColumns,
            data: tabStudents || [],
            enableBottomToolbar: false,
            enableGlobalFilterModes: true,
            enablePagination: false,
            enableColumnPinning: true,
            enableRowNumbers: false,
            enableRowVirtualization: true,
            enableRowSelection: true,
            enableSelectAll: true,
            onRowSelectionChange: setRowSelection,
            state: { rowSelection }
        });



    // popola gli oggetti usati dalle tabelle con i dati degli studenti e dei docenti
    // per la visutalizzazione delle liste
    useEffect(() => {
        const tabStudent = [];
        const tabTeacher = [];

        function convertDate(inputFormat) {
            function pad(s) { return (s < 10) ? '0' + s : s; }
            if (inputFormat) {
                var d = new Date(inputFormat)
                return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/') +
                    ' ' + [pad(d.getHours()), pad(d.getMinutes())].join(':')
            } else {
                return 'N/A'
            }
        };


        if (students) {
            students.map((value) => {
                if (value.teacher.email === apiAuthResponse || isAdmin) {

                    tabStudent.push(
                        {
                            teacher: value.teacher.email,
                            partition: value.teacher.partition,
                            email: value.email,
                            registrationDate: convertDate(value.registrationDate),
                        }
                    )
                }
                return tabStudent;
            });
            setTabStudents(tabStudent);
        }

        if (teachers) {
            teachers.map((value) => {
                tabTeacher.push(
                    {
                        teacher: value.email,
                    }
                )
                return tabTeacher;
            });
            setTabTeachers(tabTeacher);
        }




    }, [students, teachers, apiAuthResponse, isAdmin, setTabStudents]);


    // gestisce la selezione degli studenti selezionati
    // serve per popolare l'array degli studenti selezionati che vanno esclusi dalla lista
    useEffect(() => {
        if (!tabStudents || tabStudents.length === 0) {
            setSelectedStudents([]);
            return;
        }

        const selectedEmails = Object.keys(rowSelection)
            .filter(index => rowSelection[index] && tabStudents[index]) // Ensure the index is valid
            .map(index => tabStudents[index].email); // Map each index to the email of the corresponding student

        setSelectedStudents(selectedEmails);
        console.log("debug: " + JSON.stringify(selectedEmails));
    }, [rowSelection, tabStudents, setSelectedStudents]);


    // aggiorna la lista degli studenti, eliminando quelli selezionati
    useEffect(() => {
        const filteredStudents = students.filter(student =>
            !selectedStudents.includes(student.email)
        );
        setStudents(filteredStudents);
        //console.log("debug: " + JSON.stringify(filteredStudents));
    }, [selectedStudents, students, setStudents]);



    return (
        <React.Fragment>
            <Box mb={2}>
                <Typography variant="h6">Docenti gestori del laboratorio</Typography>
                <MaterialReactTable table={teachersTable} />
            </Box>

            <Box mb={2}>
                <Typography variant="h6">{labelStudentBooked}</Typography>
                <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 2 }}>
                    Selezionare gli studenti che si vuole rimuovere.
                </Typography>
                <MaterialReactTable table={studentsTable} />
            </Box>
        </React.Fragment>
    );
};

export default StudentList;