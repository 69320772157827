import React, { useState } from 'react';
import SelectBaseDc from './SelectBaseDc';
import SelectBaseOs from './SelectBaseOs';
import SelectBaseKind from './SelectBaseKind';
import SelectBaseImage from './SelectBaseImage';


function MultipleStepBaseImage(props) {
    const [statusForm, setStatusForm] = useState({
        step: 1,
        datacenter: '',
        os: '',
        kindImage: '',
    });

    const { step } = statusForm;

    var ret = null;


    const prevStep = () => {

        props.setMultipleBaseStepCompleted(false);
        switch (statusForm.step) {
            case 1:
                setStatusForm({
                    step: step - 1,
                    datacenter: statusForm.datacenter,
                    os: "",
                });
                break;
            case 2:
                setStatusForm({
                    datacenter: statusForm.datacenter,
                    os: "",
                    step: step - 1,
                });
                break;
            case 3:
                setStatusForm({
                    datacenter: statusForm.datacenter,
                    os: statusForm.os,
                    kindImage: "",
                    step: step - 1,
                });
                break;
            case 4:
                setStatusForm({
                    datacenter: statusForm.datacenter,
                    os: statusForm.os,
                    kindImage: statusForm.kindImage,
                    step: step - 1,
                });
                break;
            default:
                break;
        }
    }

    const handleSelectDc = (myDc) => {
        var myElem = {
            ...statusForm,
            datacenter: myDc,
            step: step + 1,
        }

        setStatusForm(myElem);
    }

    const handleSelectOs = (myOs) => {
        const myElem = {
            ...statusForm,
            os: myOs,
            step: step + 1
        }

        setStatusForm(myElem)
    }

    const handleSelectKindImage = (myKindImage) => {
        var myElem = {
            ...statusForm,
            kindImage: myKindImage,
            step: step + 1,
        }

        setStatusForm(myElem)

        //props.onCompletion(); // Call the onCompletion prop
    }


    switch (step) {
        case 1:
            return <SelectBaseDc
                handleSelectDc={handleSelectDc}
                statusForm={statusForm}
            />
        case 2:
            return <SelectBaseOs
                prevStep={prevStep}
                handleSelectOs={handleSelectOs}
                statusForm={statusForm}
            />
        case 3:
            return <SelectBaseKind
                prevStep={prevStep}
                handleSelectKindImage={handleSelectKindImage}
                statusForm={statusForm}
            />
        case 4:
            return <SelectBaseImage
                prevStep={prevStep}
                setBaseImage={props.setBaseImage}
                baseImage={props.baseImage}
                setIsBookable={props.setIsBookable}
                isBookable={props.isBookable}
                statusForm={statusForm}
                setRepoPkgBundleSelString={props.setRepoPkgBundleSelString}
                setMultipleBaseStepCompleted={props.setMultipleBaseStepCompleted}
            />
        default:
    }

    console.log("debug: " + JSON.stringify(statusForm))




    return ret;
}

export default MultipleStepBaseImage;