import React, { useState, useEffect, useRef } from 'react';
import AgreementLab from '../reqLab/AgreementLab';
import { Button, Typography, Link, Box } from '@mui/material';
import { useNavigate } from "react-router-dom";

function RegisterLab(props) {
    const [registerResult, setRegisterResult] = useState(-1);
    const [registerLab, setRegisterLab] = useState("");
    const [teacher, setTeacher] = useState("");
    const [partition, setPartition] = useState("");
    const [destUrl, setDestUrl] = useState("");

    const effectRan = useRef(false);

    var history = useNavigate();


    useEffect(() => {
        // questo serve per evitare che venga chiamato due volte
        // per via dello StrictMode di React (index.js)
        if (effectRan.current) {
            return;
        }
        effectRan.current = true;

        setTimeout(async () => {
            await fetch(process.env.REACT_APP_URL_REGISTER_LAB_BACKEND + '/' + props.handle, {
                credentials: 'include',
                method: "GET"
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));

                    if (data.statusRegistration === "success") {
                        setRegisterResult(0);
                        setRegisterLab(data.labName);
                        setTeacher(data.teacher);
                        setPartition(data.partition);
                        setDestUrl(data.destUrl);
                    } else if (data.statusRegistration === "alreadyRegistered") {
                        setRegisterResult(2);
                        setRegisterLab(data.labName);
                        setTeacher(data.teacher);
                        setPartition(data.partition);
                        setDestUrl(data.destUrl);
                    } else if (data.statusRegistration === "maxStudentsReached") {
                        setRegisterResult(3);
                        setRegisterLab(data.labName);
                        setTeacher(data.teacher);
                        setPartition(data.partition);
                        setDestUrl(data.destUrl);
                    } else {
                        setRegisterResult(1);
                    }
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                    setRegisterResult(-2);
                });
        });
    }, [props.handle]);

    function handleSubmit(e) {
        e.preventDefault();
        history("/")
    }

    const toggleAgreement = () => {
        if (props.onCompletion) {
            props.onCompletion();
        } else {
            console.error("onCompletion is not a function");
        }
    };

    const renderRegisterPage = (status) => {
        var ret = null;
        var urlRedirect = process.env.REACT_APP_URL_REGISTER + props.handle;

        if (registerResult === -2) {
            window.location.replace(urlRedirect);
        }

        if (props.apiAuthName) {
            console.log("debug: " + status)
            switch (status) {
                case 0:
                    ret = (
                        <React.Fragment>
                            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="h1">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</Typography>
                                    <Typography variant="h3">Registrazione avvenuta con successo!</Typography>
                                    <br />
                                    <Typography>Laboratorio: {registerLab}</Typography>
                                    <Typography>Docente: {teacher}</Typography>
                                    <Typography>Turno: {partition}</Typography>
                                    <br />
                                    <Typography>
                                        La tua Macchina Virtuale sarà disponibile entro 12 ore al seguente indirizzo:
                                        <Link href={destUrl} target="_blank" rel="noopener noreferrer">{destUrl}</Link>
                                    </Typography>
                                    <AgreementLab onCompletion={toggleAgreement} />
                                </Box>

                                <Box>
                                    <br />
                                    <Button type="submit" variant="contained">Torna alla Home Page</Button>
                                </Box>
                            </form>
                        </React.Fragment >
                    );
                    break;
                case 1:
                    ret = (
                        <React.Fragment>
                            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="h1">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</Typography>
                                    <Typography variant="h3">Registrazione fallita</Typography>
                                    <br />
                                    <Typography>Laboratorio: {registerLab}</Typography>
                                    <Typography>Docente: {teacher}</Typography>
                                    <Typography>Turno: {partition}</Typography>
                                    <br />
                                    <Typography>
                                        Indirizzo per l'iscrizione errato oppure le iscrizioni non sono abilitate per il corrente Laboratorio Virtuale.
                                        Per aprire una richiesta di assistenza clicca
                                        <Link href={process.env.REACT_APP_URL_SERVICEDESK} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_URL_SERVICEDESK}</Link>
                                    </Typography>
                                    <AgreementLab onCompletion={toggleAgreement} />
                                </Box>

                                <Box>
                                    <br />
                                    <Button type="submit" variant="contained">Torna alla Home Page</Button>
                                </Box>

                            </form>
                        </React.Fragment>
                    );
                    break;
                case 2:
                    ret = (
                        <React.Fragment>
                            <form style={{ width: "100%" }} onSubmit={handleSubmit}>

                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="h1">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</Typography>
                                    <Typography variant="h3">Registrazione già effettuata</Typography>
                                    <br />
                                    <Typography>Laboratorio: {registerLab}</Typography>
                                    <Typography>Docente: {teacher}</Typography>
                                    <Typography>Turno: {partition}</Typography>
                                    <br />
                                    <Typography>
                                        La tua Macchina Virtuale sarà disponibile entro 12 ore al seguente indirizzo:
                                        <Link href={destUrl} target="_blank" rel="noopener noreferrer">{destUrl}</Link>
                                    </Typography>
                                    <AgreementLab onCompletion={toggleAgreement} />
                                </Box>

                                <Box>
                                    <br />
                                    <Button type="submit" variant="contained">Torna alla Home Page</Button>
                                </Box>


                            </form>

                        </React.Fragment >
                    );
                    break;
                case 3:
                    ret = (
                        <React.Fragment>
                            <form style={{ width: "100%" }} onSubmit={handleSubmit}>

                                <Box sx={{ width: '100%' }}>
                                    <Typography variant="h1">Benvenuto {props.apiAuthName} {props.apiAuthSurname}</Typography>
                                    <Typography variant="h3">Raggiunto limite di studenti iscritti per questo laboratorio</Typography>
                                    <br />
                                    <Typography>Laboratorio: {registerLab}</Typography>
                                    <Typography>Docente: {teacher}</Typography>
                                    <Typography>Turno: {partition}</Typography>
                                    <br />
                                    <Typography>
                                        Contattare il docente di riferimento per ulteriori informazioni.
                                    </Typography>
                                    <AgreementLab onCompletion={toggleAgreement} />
                                </Box>

                                <Box>
                                    <br />
                                    <Button type="submit" variant="contained">Torna alla Home Page</Button>
                                </Box>


                            </form>

                        </React.Fragment >
                    );
                    break;
                default:
            }
        } else {
            ret = null;
        }


        return ret;
    }


    return (renderRegisterPage(registerResult));
}

export default RegisterLab;