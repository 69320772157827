import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, Card, Typography } from '@mui/material';

function SelectExpDate(props) {

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('it-IT', options);
    };

    const {
        onExpDateChange,
        expirationMonthDuration,
        expirationDate,
    } = props;

    const handleSelectChange = (event) => {
        const newExpirationMonthDuration = event.target.value;
        if (onExpDateChange) {
            onExpDateChange(newExpirationMonthDuration);
        }
    };

    const savedLabExpDate = (ed) => {
        if (ed) {
            const formattedDate = formatDate(ed);
            return (
                <Typography variant="h6">La scadenza corrente del laboratorio virtuale precedentemente salvata è: {formattedDate}</Typography>
            )
        }

    }

    return (
        <Card sx={{ padding: 3 }}>
            <Box>
                <div>
                    <InputLabel>Durata del laboratorio virtuale</InputLabel>
                </div>

                <FormControl fullWidth size="large">
                    <Select
                        labelId="exp-date-label"
                        value={expirationMonthDuration}
                        onChange={handleSelectChange}
                        displayEmpty
                    >
                        <MenuItem value="" disabled>
                            Durata in mesi
                        </MenuItem>
                        <MenuItem value="18">18 mesi</MenuItem>
                        <MenuItem value="12">12 mesi</MenuItem>
                        <MenuItem value="6">6 mesi</MenuItem>
                        <MenuItem value="3">3 mesi</MenuItem>
                    </Select>
                </FormControl>
                {savedLabExpDate(expirationDate)}
            </Box>
        </Card>
    );
}

export default SelectExpDate;