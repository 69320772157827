import React, { useState } from 'react';
import { Grid, Box, Card, Divider, Button, Typography, TextField } from '@mui/material';
import ClipLoader from 'react-spinners/ClipLoader';
import DropLabConfirm from '../scheduleLab/DropLabConfirm';



function LabConsolleDroppedPage(props) {
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isSubmitble, setIsSubmitble] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [statusRequest, setStatusRequest] = useState();

    const {
        labProps,
    } = props;


    var ret = null;



    const onSubmit = (e) => {
        e.preventDefault();
        var formBody = [];
        var url;

        url = process.env.REACT_APP_URL_DROP_LAB;
        console.log("url: " + url)

        const payload = (
            {
                idLab: props.labProps.idLab,
            }
        );

        formBody.push("payload=" + JSON.stringify(payload));

        setTimeout(async () => {
            console.log("payload: " + JSON.stringify(payload));
            setIsLoading(1);
            await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            })
                .then((resp) => {
                    return resp.json()
                })
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setIsSubmitted(1);
                    setIsLoading(0);
                    setStatusRequest(data.statusRequest);
                    setIsSubmitble(true);
                })
                .catch((error) => {
                    console.log(error, "Errore!")
                });
        });
    }


    ret = (
        <React.Fragment>
            <Typography variant="h4" className="title-page">
                Cancellazione Laboratorio Virtuale
            </Typography>
            <Box sx={{ padding: 3 }}>
                <Typography variant="body1" gutterBottom>
                    Da questa pagina è possibile cancellare un Laboratorio Virtuale
                </Typography>                
                <Typography variant="body1" gutterBottom>
                    <strong>idLab</strong> {labProps.idLab}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Nome laboratorio</strong> {labProps.labName}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Descrizione</strong> {labProps.labDescription}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Immagine base</strong> {labProps.baseImage.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Dimensionamento</strong> {labProps.baseImage.size}
                </Typography>

            </Box>


            <Divider />

            <form onSubmit={onSubmit}>

                <Grid container spacing={2} style={{ width: "100%" }}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">E-mail richiedente</Typography>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder={`${labProps.email}`}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Identificativo Laboratorio</Typography>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder={`${labProps.idLab}`}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Tipologia Laboratorio</Typography>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder={`${labProps.labType}`}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Sistema Operativo</Typography>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder={`${labProps.baseImage.os}`}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Immagine base</Typography>
                        <TextField
                            fullWidth
                            type="text"
                            placeholder={`${labProps.baseImage.name}`}
                            InputProps={{
                                readOnly: true,
                            }}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Attività didattica collegata al laboratorio virtuale</Typography>
                        <Card style={{ padding: '1rem' }}>
                            <Typography variant="subtitle2">Descrizione attività didattica</Typography>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={`${labProps.adSel.anaAfDescIta}`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                margin="normal"
                            />
                            <Typography variant="subtitle2">Descrizione modulo</Typography>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={`${labProps.adSel.anaModDescIta}`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                margin="normal"
                            />
                            <Typography variant="subtitle2">Codice Easy</Typography>
                            <TextField
                                fullWidth
                                type="text"
                                placeholder={`${labProps.adSel.easyAdCod}`}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                                margin="normal"
                            />
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <div>
                    <DropLabConfirm
                        isSubmitted={isSubmitted}
                        isSubmitble={isSubmitble}
                        statusRequest={statusRequest}
                        apiAuthGroups={props.apiAuthGroups}
                        buttonDescription={"Cancella il laboratorio"}
                    />
                    <br />
                    <ClipLoader
                        size={35}
                        color={"#a71e3b"}
                        loading={isLoading}
                    />
                </div>

            </form>

            <br />

            <hr />
            <Button
                size="sm"
                variant="contained"
                onClick={() => { props.setIsShowDropLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>


        </React.Fragment>
    );





    if (props.labProps) {
        return ret;
    } else {
        return (
            <Button
                size="sm"
                variant="contained"
                onClick={() => { props.setIsShowScheduleLab(false) }}
            >
                Torna alla Consolle pianificazione Laboratori
            </Button>
        );
    }

}

export default LabConsolleDroppedPage;
