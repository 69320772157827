import React from 'react';
import { Button, Container, Card, Typography, Box, Grid, FormControl } from '@mui/material';


function SelectBaseDc(props) {

    const onClickDcUnimib = () => {
        props.handleSelectDc('unimib')
    }

    const onClickDcExamUnimib = () => {
        props.handleSelectDc('examunimib')
    }

    return (
        <Container>
            <form>
                <Box mb={3}>
                    <Typography variant="h6">Datacenter Unimib</Typography>
                </Box>


                <Card sx={{ padding: 3 }}>
                    <Grid container spacing={4} >
                        <Grid item xs={12} md={4} >
                            <Card sx={{
                                padding: 2,
                                height: 'auto', minHeight: '300px',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'space-between'
                            }}>
                                <FormControl fullWidth>
                                    <Typography variant="h4">Lezioni Bicocca</Typography>
                                    <Typography variant="subtitle1">Il laboratorio verrà collocato su infrastrutture Bicocca per lezione ad accesso pubblico (selezionabile in caso di indisponibilità dell'infrastruttura esami)</Typography>
                                </FormControl>
                            </Card>
                            <Button variant="contained" onClick={onClickDcUnimib} style={{ marginTop: '16px' }}>
                                Seleziona
                            </Button>

                        </Grid>
                        <Grid item xs={12} md={4} >
                            <Card sx={{
                                padding: 2,
                                height: 'auto', minHeight: '300px',
                                display: 'flex',
                                flexDirection: 'column', justifyContent: 'space-between'
                            }}>
                                <FormControl fullWidth>
                                    <Typography variant="h4">Esami Bicocca</Typography>
                                    <Typography variant="subtitle1">Il laboratorio verrà collocato su infrastrutture Bicocca per esami ad accesso privato</Typography>
                                </FormControl>
                            </Card>
                            <Button variant="contained" onClick={onClickDcExamUnimib} style={{ marginTop: '16px' }}>
                                Seleziona
                            </Button>

                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box mb={2} p={1} className="shadow-lg bg-white rounded">
                                <Typography variant="body1">1. Datacenter</Typography>
                            </Box>
                            <Box mb={2} p={1} className="shadow-none bg-light rounded">
                                <Typography variant="body1">2. Sistema operativo</Typography>
                            </Box>
                            <Box mb={2} p={1} className="shadow-none bg-light rounded">
                                <Typography variant="body1">3. Tipo immagine</Typography>
                            </Box>
                            <Box mb={2} p={1} className="shadow-none bg-light rounded">
                                <Typography variant="body1">4. Selezione immagine</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </form>
        </Container>
    );
}

export default SelectBaseDc;